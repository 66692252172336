import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../components/Layout'
import { SEOMetaTags } from 'blog-components'

import * as messages from '../../messages.json'

export default class RecommendedGear extends React.Component {
  successComponent() {
    return (
      <div className="main-container">
        <div className="row justify-content-center no-gutters text-center">
          <div className="col-sm-8">
            <h1 className="display-4 py-5">
              {messages.pages.contactus.components.success.heading}
            </h1>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const site = get(this, 'props.data.site')
    const bestApproachShoeImage = get(this, 'props.data.bestApproachShoeImage')

    return (
      <Layout>
        <SEOMetaTags
          path="/recommended-gear/"
          site={site}
          title={messages.pages.recommendedGear.metaData.title}
          description={messages.pages.recommendedGear.metaData.description}
          imageUrl={site.siteMetadata.siteUrl + '/main-logo.png'}
        />
        <div className="container">
          <div className="row justify-content-center no-gutters px-3">
            <div className="col-12">
              <h1 className="display-4 text-center py-5 ">
                {messages.pages.recommendedGear.components.heading}
              </h1>
              <p>{messages.pages.recommendedGear.components.paragraph_1}</p>
              <p>{messages.pages.recommendedGear.components.paragraph_2}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <a href="./best-approach-shoe/">
                  <div>
                    <Img fluid={bestApproachShoeImage.fluid} />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query RecommendedGearQuery {
    site {
      ...SiteInformation
    }
    bestApproachShoeImage: contentfulAsset(
      file: { fileName: { eq: "best-approach-shoe-scarpa-vortex-xcr-gtx.png" } }
    ) {
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
  }
`
